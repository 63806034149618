/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from "react";
import { IonGrid, IonRow, IonCard } from "@ionic/react";
import "./YappaCaliente.css";
import { BtnCanjearYappa } from "../botonCanjear/BTNCanjearYappa";
import { BtnsLikeShare } from "../botonesLikeShare/BtnsLikeShare";
import { BtnVerYappa } from "../btnVerYappa/BtnVerYappa";
import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

export const YappaCaliente = () => {
  const [yappa, setYappa] = useState<any[]>([]);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 700 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  useEffect(() => {
    const getYappas = async () => {
      const response = await fetch("https://mi.yappa.ec/api/yappa_caliente");
      const data = await response.json();

      setYappa(data.original);
    };

    getYappas();
  }, []);

  useEffect(() => {
    console.log(yappa);
  });

  const get_day = (date: any) => {
    let allDate = new Date(date);
    return allDate.toString().split(" ")[2];
  };

  const get_month = (date: any) => {
    const month: any = {
      Jan: "enero",
      Feb: "febrero",
      Mar: "marzo",
      Apr: "abril",
      May: "mayo",
      Jun: "junio",
      Jul: "julio",
      Aug: "agosto",
      Sep: "septiembre",
      Oct: "octubre",
      Nov: "noviembre",
      Dec: "diciembre",
    };
    let allDate = new Date(date);
    return month[allDate.toString().split(" ")[1]];
  };

  return (
    <>
      {yappa.length > 0 ? (
        <>
          <section className="fondoYappas">
            <h2 className="tituloCategoria"> ¡Yappa caliente! </h2>
            <Carousel responsive={responsive} containerClass="">
              {yappa.map((value, index) => {
                return (
                  <section key={value.id}>
                    <IonCard className="contenCard">
                      <img
                        className="imgYappa"
                        src={value.url}
                        onClick={() => {
                          let path = sessionStorage.getItem("path");
                          path !== ""
                            ? (path += `,${location.href}`)
                            : (path += `${location.href}`);
                          sessionStorage.setItem("path", `${path}`);
                          location.href = `yappa/${value?.subcategoria.replaceAll(
                            " / ",
                            "-"
                          )}/${value.nombre.replaceAll(" ", "-")}`;
                        }}
                      ></img>

                      <IonGrid className="contenGrid">
                        <section className="seccionContenido">
                          <div className="tituloandbtnyappa">
                            <div>
                              <h6>{value.subcategoria}</h6>
                              <h4 className="nombreEmpresa">
                                {value.negocio_nombre}
                              </h4>
                              <h2 className="titulo">{value.nombre}</h2>
                            </div>
                            <div className="botonesLike">
                              <BtnsLikeShare
                                id_yappa={value.id}
                                wa={value?.celular_sucursal}
                                subcategoria={value?.subcategoria.replaceAll(
                                  " / ",
                                  "-"
                                )}
                                yappa={value?.nombre.replaceAll(" ", "-")}
                              />
                            </div>
                          </div>

                          <div
                            className="textosDetalles"
                            onClick={() => {
                              let path = sessionStorage.getItem("path");
                              path !== ""
                                ? (path += `,${location.href}`)
                                : (path += `${location.href}`);
                              sessionStorage.setItem("path", `${path}`);
                              location.href = `yappa/${value?.subcategoria.replaceAll(
                                " / ",
                                "-"
                              )}/${value.nombre.replaceAll(" ", "-")}`;
                            }}
                          >
                            <p> Vigencia: </p>
                            <p>Yappas disponibles:</p>
                          </div>
                          <div
                            className="textosDetalles2"
                            onClick={() => {
                              let path = sessionStorage.getItem("path");
                              path !== ""
                                ? (path += `,${location.href}`)
                                : (path += `${location.href}`);
                              sessionStorage.setItem("path", `${path}`);
                              location.href = `yappa/${value?.subcategoria.replaceAll(
                                " / ",
                                "-"
                              )}/${value.nombre.replaceAll(" ", "-")}`;
                            }}
                          >
                            <div className="infoFecha">
                              <h5 className="fechaTextoYC">
                                {/* Del {get_day(value.fecha_publicacion.split(" ")[0])}{" "}
                          de {get_month(value.fecha_publicacion.split(" ")[0])}{" "}
                          al {get_day(value.fecha_expiracion.split(" ")[0])} de{" "}
                          {get_month(value.fecha_expiracion.split(" ")[0])}{" "} */}
                                {value.fecha_expiracion.split(" ")[0]}
                              </h5>
                            </div>
                            <div className="cantidadYappas">
                              <h4>
                                {value.cantidad < 0
                                  ? "Agotada"
                                  : value.cantidad}
                              </h4>
                            </div>
                          </div>
                        </section>

                        <section
                          className="colBtnCanjear"
                          onClick={() => {
                            let path = sessionStorage.getItem("path");
                            path !== ""
                              ? (path += `,${location.href}`)
                              : (path += `${location.href}`);
                            sessionStorage.setItem("path", `${path}`);
                            location.href = `yappa/${value?.subcategoria.replaceAll(
                              " / ",
                              "-"
                            )}/${value.nombre.replaceAll(" ", "-")}`;
                          }}
                        >
                          {/* <BtnVerYappa
                            obj={value.nombre.replaceAll(" ", "-")}
                            category={value?.subcategoria.replaceAll(
                              " / ",
                              "-"
                            )}
                          /> */}
                          {/* <BtnCanjearYappa obj={yappa[index]} /> */}
                        </section>
                      </IonGrid>
                    </IonCard>
                  </section>
                );
              })}
            </Carousel>
          </section>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
