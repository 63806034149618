import {
  IonButton,
  IonButtons,
  IonIcon,
  useIonAlert,
  useIonLoading,
} from "@ionic/react";
import { RWebShare } from "react-web-share";
import "./btnsLikeShare.css";
import { bookmarkOutline } from "ionicons/icons";
import { shareSocialOutline } from "ionicons/icons";
import { logoWhatsapp } from "ionicons/icons";

import AddToPath from "../../funcs/AddToPath";

export const BtnsLikeShare = (props) => {
  const { id_yappa, wa, subcategoria, yappa } = props;
  const [presentAlert] = useIonAlert();
  const [present] = useIonLoading();

  const validarSesion = () => {
    return sessionStorage.getItem("id") != null &&
      sessionStorage.getItem("id") !== "null"
      ? true
      : false;
  };

  const getYappasGuardadas = async () => {
    const body = {
      id: +sessionStorage.getItem("id"),
    };

    await fetch("https://mi.yappa.ec/api/mostrar_yappa_guardada", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    });
  };

  const save = async (id_yappa) => {
    //if (validarSesion()) {
    const body = {
      id_usuario: +sessionStorage.getItem("id"),
      id_yappa: id_yappa,
    };

    await fetch("https://mi.yappa.ec/api/guardar_yappa", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    });

    present({
      message: "Guardando Yappa en 'Mis Yappas'",
      duration: 2000,
      cssClass: "MsgOk",
      translucent: true,
      showBackdrop: true,
    });

    getYappasGuardadas();
    // } else {
    //   sessionStorage.save = id_yappa;

    //   await presentAlert({
    //     header: "Recuerda que para guardar tu yappa debes tener una cuenta.",
    //     cssClass: "MsgError",
    //     buttons: [
    //       {
    //         text: "Ingresar",
    //         handler: () => {
    //           AddToPath("/login");
    //         },
    //       },
    //       {
    //         text: "Crear cuenta",
    //         handler: () => {
    //           AddToPath("/registroUser");
    //         },
    //       },
    //     ],
    //   });
    // }
  };

  return (
    <IonButtons class="btns">
      <IonButton
        class="btnLike"
        onClick={() => {
          save(id_yappa);
        }}
      >
        <IonIcon icon={bookmarkOutline} />
      </IonButton>
      {/* <RWebShare
        data={{
          text: "¡Hey! te tengo una Yappa buenísima, Yappeala aquí: ",
          url: `https://mi.yappa.ec/yappa/${id_yappa}`,
          title: "Comparte tu Yappa",
        }}
      >
        <IonButton class="btnShare">
          <IonIcon icon={shareSocialOutline}></IonIcon>
        </IonButton>
      </RWebShare> */}
      <IonButton
        class="btnShare"
        onClick={() => {
          // console.log(
          //   `https://wa.me/593${wa}?text=Quiero%20yappear:%20https://mi.yappa.ec/yappa/${subcategoria}/${yappa}`
          // );
          // eslint-disable-next-line no-restricted-globals
          location.href = `https://wa.me/593${wa}?text=Quiero%20yappear:%20https://yappa.ec/yappa/${subcategoria}/${yappa}`;
        }}
      >
        <IonIcon icon={logoWhatsapp}></IonIcon>
      </IonButton>
    </IonButtons>
  );
};
