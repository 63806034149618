/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { IonCard, IonCardContent } from "@ionic/react";
import React, { useState, useEffect } from "react";
import "./yappasGuardadas.css";
import { BtnsLikeShare } from "../botonesLikeShare/BtnsLikeShare";
import { BtnCanjearYappa } from "../botonCanjear/BTNCanjearYappa";
import { BtnVerYappa } from "../btnVerYappa/BtnVerYappa";

function MisYappasGuardadas(props: any) {
  const { path } = props;
  const [items, setItems] = useState<any[]>([]);
  const [yappaGuardada, setYappaGuardada] = useState<any[]>([]);

  const validarSesion = () => {
    return sessionStorage.getItem("id") != null &&
      sessionStorage.getItem("id") !== "null"
      ? true
      : false;
  };

  useEffect(() => {
    const getYappasGuardadas = async () => {
      if (validarSesion()) {
        const body = {
          id: +sessionStorage.getItem("id")!,
        };

        const response = await fetch(
          "https://mi.yappa.ec/api/mostrar_yappa_guardada",
          {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
            },
          }
        );

        const data = await response.json();

        if ((await data.original?.length) > 0) {
          setYappaGuardada(data.original);
        } else {
          setYappaGuardada([
            {
              id: 0,
              nombre: "!Ups¡ Aún no haz guardado Yappas",
              url: "https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/a6999688-b9f4-48cf-5f3f-9b13e5c33e00/public",
              descripcion: "!Ups¡ Aún no haz guardado Yappas",
            },
          ]);
        }
      }
    };

    getYappasGuardadas();
  }, []);

  const get_day = (date: any) => {
    let allDate = new Date(date);
    return allDate.toString().split(" ")[2];
  };

  const get_month = (date: any) => {
    const month: any = {
      Jan: "enero",
      Feb: "febrero",
      Mar: "marzo",
      Apr: "abril",
      May: "mayo",
      Jun: "junio",
      Jul: "julio",
      Aug: "agosto",
      Sep: "septiembre",
      Oct: "octubre",
      Nov: "noviembre",
      Dec: "diciembre",
    };
    let allDate = new Date(date);
    return month[allDate.toString().split(" ")[1]];
  };

  return (
    <>
      <section className="gridCard" role="article">
        {yappaGuardada.map((item, index) => (
          <div key={index}>
            {item.id > 0 ? (
              <IonCard className="yappaCard" key={index}>
                <img
                  className="imagenCard"
                  src={item.url}
                  onClick={() => {
                    let path = sessionStorage.getItem("path");
                    path !== ""
                      ? (path += `,${location.href}`)
                      : (path += `${location.href}`);
                    sessionStorage.setItem("path", `${path}`);
                    location.href = `yappa/${item?.subcategoria.replaceAll(
                            " / ",
                            "-"
                          )}/${item.nombre.replaceAll(" ", "-")}`;
                  }}
                ></img>

                <section className="contenidoCard">
                  <div className="tituloYBTNs">
                    <div>
                      <h6 className="nombreCategoria">{item.subcategoria}</h6>
                      <h4 className="nombreNegocio">{item.negocio}</h4>
                      <h2 className="nombreYappa">{item.nombre}</h2>
                    </div>
                    <div className="botoneslike">
                      <BtnsLikeShare id_yappa={item.id}></BtnsLikeShare>
                    </div>
                  </div>

                  <div className="detallesYappa">
                    <p>Vigencia: </p>
                    <p>Yappas disponibles: </p>
                  </div>
                  <div className="detalles2Yappas">
                    <div className="detalleFecha">
                      <h4 className="fechaTexto">
                        Disponible hasta el{" "}
                        {get_day(item.fecha_expiracion.split(" ")[0])} de{" "}
                        {get_month(item.fecha_expiracion.split(" ")[0])}{" "}
                      </h4>
                    </div>
                    <div className="detalleCantidadYappas">
                      <h4 className="cantidaYappas">
                        {item.cantidad_disponible < "Agotada"
                          ? 0
                          : item.cantidad_disponible}
                      </h4>
                    </div>
                  </div>
                </section>
                <section className="seccionBotonVerYappa">
                  <button
                    className="btnVerYappainfo"
                    onClick={() => {
                      let path = sessionStorage.getItem("path");
                      path !== ""
                        ? (path += `,${location.href}`)
                        : (path += `${location.href}`);
                      sessionStorage.setItem("path", `${path}`);
                      location.href = `yappa/${item?.subcategoria.replaceAll(
                            " / ",
                            "-"
                          )}/${item.nombre.replaceAll(" ", "-")}`;
                    }}
                  >
                    <h3> Ver Yappa </h3>
                    <img
                      className="imgBtnveryappa"
                      src="https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/189794e5-5209-40a6-9fbb-3b4d97feee00/public"
                      alt="icon"
                    />
                  </button>
                  {/* <BtnVerYappa obj={item.id}></BtnVerYappa> */}
                </section>

                {/* <div >
              <p>
                <b>Descripción: </b>
              </p>
              <p>
                <b>Vigencia: </b>{item.fecha_expiracion.split(" ")[0]}
              </p>
              <p>
                <b>Ciudad: </b>{item.ciudad}
              </p>
              <p>
                <b>Restricciones: </b>{item.restricciones}
              </p>
            </div>

          
          <div className="btnCanjearYappa">
            <BtnCanjearYappa obj={item.id} ></BtnCanjearYappa>
          </div> */}
              </IonCard>
            ) : (
              <IonCard className="yappaCard" key={index}>
                <img
                  className="imagenCard"
                  src={item.url}
                  onClick={() => {
                    let path = sessionStorage.getItem("path");
                    path !== ""
                      ? (path += `,${location.href}`)
                      : (path += `${location.href}`);
                    sessionStorage.setItem("path", `${path}`);
                    location.href = `yappa/${item.id}`;
                  }}
                ></img>

                <section className="contenidoCard">
                  <div className="tituloYBTNs">
                    <div>
                      <h4 className="nombreNegocio">{item.negocio}</h4>
                      <h2 className="nombreYappa">{item.nombre}</h2>
                    </div>
                  </div>
                </section>
              </IonCard>
            )}
          </div>
        ))}
      </section>
    </>
  );
}
export default MisYappasGuardadas;
