/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/react";

import "./YappaCard.css";
import { BtnsLikeShare } from "../botonesLikeShare/BtnsLikeShare";
import { BtnCanjearYappa } from "../botonCanjear/BTNCanjearYappa";
import { BtnVerYappa } from "../btnVerYappa/BtnVerYappa";

function Example(props: any) {
  const {
    id_yappa,
    titulo,
    url,
    categoria,
    cantidadYappas,
    negocio,
    fecha_publicacion,
    fecha_expiracion,
  } = props;

  try {
  } catch (error) {}
  const get_day = (date: any) => {
    let allDate = new Date(date);
    return allDate.toString().split(" ")[2];
  };

  const get_month = (date: any) => {
    const month: any = {
      Jan: "enero",
      Feb: "febrero",
      Mar: "marzo",
      Apr: "abril",
      May: "mayo",
      Jun: "junio",
      Jul: "julio",
      Aug: "agosto",
      Sep: "septiembre",
      Oct: "octubre",
      Nov: "noviembre",
      Dec: "diciembre",
    };

    let allDate = new Date(date);
    return month[allDate.toString().split(" ")[1]];
  };

  return (
    <IonCard className="yappaCard">
      <img
        className="imgCard"
        alt="Silhouette of mountains"
        src={url}
        onClick={() => {
          let path = sessionStorage.getItem("path");
          path !== ""
            ? (path += `,${location.href}`)
            : (path += `${location.href}`);
          sessionStorage.setItem("path", `${path}`);
          location.href = `yappa/${categoria.replaceAll(
            " / ",
            "-"
          )}/${titulo.replaceAll(" ", "-")}`;
        }}
      />
      <section className="contenidoYappa">
        <div className="headerYappa">
          <div>
            <h6 className="nombreCateogira">{categoria}</h6>
            <h4 className="nombreNegocio">{negocio}</h4>
            <h2 className="nombreYappa">{titulo}</h2>
          </div>
          <div className="botonesLikeShare">
            <BtnsLikeShare
              id_yappa={titulo.replaceAll(" ", "-")}
            ></BtnsLikeShare>
          </div>
        </div>
        <div className="detallesYappa">
          <p>Vigencia: </p>
          <p>Yappas Disponibles: </p>
        </div>
        <div className="detalles2Yappa">
          <div className="detalleFecha">
            <h5 className="fechaTexto">
              {/* Del {get_day(fecha_publicacion.split(" ")[0])} de{" "}
              {get_month(fecha_publicacion.split(" ")[0])} al al{" "}
              {get_day(fecha_expiracion.split(" ")[0])} de{" "}
              {get_month(fecha_expiracion.split(" ")[0])}{" "} */}
              {fecha_expiracion.split(" ")[0]}
            </h5>
          </div>
          <div className="detalleCantidadYappas">
            <h4 className="cantidaYappas">
              {cantidadYappas < 0 ? "Agotada" : cantidadYappas}
            </h4>
          </div>
        </div>
      </section>
      <section className="seccionBotonVerYappa">
        {/* <BtnVerYappa obj={titulo.replaceAll(" ", "-")} category={categoria.replaceAll(" / ", "-")} /> */}
      </section>
    </IonCard>
  );
}
export default Example;
