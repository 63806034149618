/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-globals */
import {
  IonCard,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
// import YappaCard from "../../componentes/tarjetas/YappaCard";
import "./historial.css";
import { BtnComentarYappa } from "../../componentes/botonComentario/BtnComentario";
import MenuOver from "../../componentes/header/MenuOver";
import { BtnBack } from "../../componentes/btnBack/BtnBack";

export const Historial = () => {
  const [yappa, setYappa] = useState<any[]>([]);

  const yappas_canjeadas = async () => {
    if (
      sessionStorage.getItem("id") !== "null" &&
      sessionStorage.getItem("id") !== null
    ) {
      const body = { id_usuario: sessionStorage.getItem("id") };
      const response = await fetch(
        "https://mi.yappa.ec/api/yappa_canjeadas_usuario",
        {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify(body),
        }
      );

      const data = await response.json();

      if ((await data.original?.length) > 0) {
        setYappa(data.original);
      } else {
        setYappa([
          {
            id: 0,
            nombre: "!Ups¡ Aún no haz canjeado tus Yappas",
            url: "https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/a6999688-b9f4-48cf-5f3f-9b13e5c33e00/public",
            descripcion: "!Ups¡ Aún no haz canjeado tus Yappas",
          },
        ]);
      }
    }
  };

  useEffect(() => {
    yappas_canjeadas();
    let path = sessionStorage.getItem("path");
    path !== "" ? (path += `,${location.href}`) : (path += `${location.href}`);
    sessionStorage.setItem("path", `${path}`);
  }, []);

  return (
    <>
      <MenuOver />
      <IonPage id="main-content">
        <IonHeader>
          <section className="headerNavBarA">
            <div className="cssBack">
              <BtnBack />
            </div>
            <div>
              <a href={"/inicio"}>
                <img
                  className="logoMenuYappa"
                  alt="logo yappa"
                  src={
                    "https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/9fc0ed25-63ad-4365-d2e4-6739bc7f2c00/public"
                  }
                />
              </a>
            </div>
            <div className="cssBottonMenu">
              <IonMenuButton></IonMenuButton>
            </div>
          </section>
        </IonHeader>

        <IonHeader>
          <IonToolbar>
            <IonTitle class="tituloHistorial">Mi Historial de Yappas</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <section className="gridCard">
            {yappa.map((value, index) => {
              return (
                <IonCard className="yappaCardCanjeada" key={value.id}>
                  <section className="informacionHistotial">
                    <img className="imagenCard" src={value.url} />
                    <section className="seccionDatos">
                      <div>
                        <h6 className="nombreCateogira">
                          {value.subcategoria}
                        </h6>
                        <h4 className="nombreNegocio">{value.negocio}</h4>
                        <h2 className="nombreYappa">{value.nombre}</h2>
                      </div>
                    </section>
                  </section>

                  <section className="btnComentar">
                    {/* <BtnComentarYappa obj={`/valoracion/${value.id}`} message={'Comentario'}></BtnComentarYappa> */}
                    {/* <BtnComentarYappa
                      obj={`/qr/${value.id}`}
                      message={"Canjear"}
                    ></BtnComentarYappa> */}
                    {value.id === 0 ? (
                      // <BtnComentarYappa
                      //   obj={`/yappa/${value.id}`}
                      //   message={"Ver"}
                      // ></BtnComentarYappa>
                      <></>
                    ) : (
                      <></>
                    )}
                  </section>
                </IonCard>
              );
            })}
          </section>
        </IonContent>
      </IonPage>
    </>
  );
};
